import { defineStore } from 'pinia'

export const useLoginModalStore = defineStore('useLoginModal', () => {
    const isVisible = ref(false)

    const show = () => {
        isVisible.value = true
    }

    const hide = () => {
        isVisible.value = false
    }

    return {
        isVisible,
        loginModalShow: show,
        loginModalHide: hide,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useLoginModalStore, import.meta.hot))
}
